/* eslint-disable camelcase */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { getEnvelopesAggs, getExperiments } from 'selectors/envelopes';
import { useSelector } from 'store';
import ModelMetricsCardsSection, { ModelMetricsType } from './ModelMetricsCardsSection';
import ModelMetricsFilters from './ModelMetricsFilters';
import ModelsMetricsHeader from './ModelsMetricsHeader';

const ModelsMetricsContent: React.FC = () => {
  const aggs = useSelector(getEnvelopesAggs);
  const metrics = useSelector(getExperiments);

  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [compareMode, setCompareMode] = useState(false);
  const [performanceFn, setPerformanceFn] =
    useState<(a: ModelMetricsType, b: ModelMetricsType) => number>();

  const handleCompare = (): void => {
    if (selectedModels.length === 2) {
      setCompareMode(true);
    }
  };

  const handleClearSelection = (): void => {
    setCompareMode(false);
    setSelectedModels([]);
  };

  return (
    <div className="w-full h-full pb-6 px-6 flex flex-col gap-3 overflow-auto pt-6">
      <ModelsMetricsHeader />
      <div className="w-full h-full flex flex-row">
        <div className="flex flex-col flex-grow gap-4">
          <ModelMetricsFilters resource="envelopes" setPerformanceFn={setPerformanceFn} />
          <div className="flex flex-col">
            {metrics.map((metric) => (
              <ModelMetricsCardsSection
                key={JSON.stringify(metric)}
                // @ts-ignore
                exp={metric}
                aggs={aggs}
                selectedModels={selectedModels}
                setSelectedModels={setSelectedModels}
                compareMode={compareMode ? 'left' : ''}
                performanceFn={performanceFn}
              />
            ))}
          </div>
        </div>
        <div className="w-1/2 h-full pl-6 flex flex-col items-center" data-testid="compare-section">
          {compareMode && (
            <div className="flex flex-col pt-22">
              {metrics.map((metric) => (
                <ModelMetricsCardsSection
                  key={JSON.stringify(metric)}
                  // @ts-ignore
                  exp={metric}
                  aggs={aggs}
                  selectedModels={selectedModels}
                  setSelectedModels={setSelectedModels}
                  compareMode={compareMode ? 'right' : ''}
                  performanceFn={performanceFn}
                />
              ))}
            </div>
          )}

          {selectedModels.length === 2 && (
            <div
              className={`w-1/2 h-full pl-6 ${
                compareMode ? 'pt-4' : 'pt-80'
              } flex flex-col items-center`}
            >
              <div className="flex flex-row justify-center gap-2">
                <button
                  type="button"
                  className="button button--primary h-8 whitespace-no-wrap"
                  disabled={selectedModels.length !== 2 || !!compareMode}
                  onClick={handleCompare}
                >
                  Compare Performance
                </button>
                <button
                  type="button"
                  className="button button--secondary h-8 whitespace-no-wrap"
                  onClick={handleClearSelection}
                >
                  <span className="font-bold">Clear Selection</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModelsMetricsContent;
