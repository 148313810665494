/* eslint-disable max-lines */
import { generateTerms, receiveNewKeyword } from 'actions/identifier';
import LoadingIndicator from 'components/LoadingIndicator';
import PapaParse from 'papaparse';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFormSpec, getGeneratedTerms, getGeneratedTermsLoading } from 'selectors/identifier';
import { useSelector } from 'store';
import type { LanguageMatcher, LanguageMatcherType, UUID } from 'types';
import LanguageMatcherList from '../LanguageMatcherList';
import RenderForm from './RenderForm';

type ComponentProps = {
  languageMatcherId: UUID;
  type: LanguageMatcherType;
  form: keyof LanguageMatcher;
  enableGenerate?: boolean;
  enableImport?: boolean;
  useDisabledColor?: boolean;
};

const Terms: React.FC<ComponentProps> = (props) => {
  const {
    languageMatcherId,
    type,
    form,
    enableGenerate = false,
    enableImport = false,
    useDisabledColor = false,
  } = props;
  const dispatch = useDispatch();
  const { inputType, inputName, inputValue, inputErrors, inputChoices, inputHidden } = useSelector(
    (state) => getFormSpec(state, type, form, languageMatcherId)
  );

  const generatedTerms = useSelector(getGeneratedTerms);
  const generateTermsLoading = useSelector(getGeneratedTermsLoading);

  const [error, setError] = useState<string | null>(null);
  const [showAll, setShowAll] = useState(false);

  const fileInputEl = useRef<HTMLInputElement>(null);
  const [fileData, setFileData] = React.useState<Record<string, string>[] | null>([]);

  const formatedFileData = fileData?.reduce<Record<string, string[]>>((acc, item) => {
    Object.entries(item).forEach(([key, value]) => {
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(value);
    });
    return acc;
  }, {});

  useEffect(() => {
    if (formatedFileData && Object.keys(formatedFileData).length && inputName === 'Terms') {
      dispatch(
        receiveNewKeyword({
          languageMatcherId,
          parentType: type,
          data: {
            key: form,
            value: [...new Set(formatedFileData.terms)],
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(fileData)]);

  useEffect(() => {
    if (inputErrors && inputErrors.includes(form)) {
      setError('Must add at least one permutation');
    } else {
      setError(null);
    }
  }, [form, inputErrors]);

  const handleFileClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();

    if (fileInputEl.current != null) {
      fileInputEl.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const fileReader = new FileReader();
    if (e.target.files != null) {
      fileReader.readAsText(e.target.files[0], 'UTF-8');
      fileReader.onload = (event): void => {
        if (event.target != null && event.target.result != null) {
          const parsed = PapaParse.parse(event.target.result as string, {
            header: true,
            skipEmptyLines: true,
          });
          setFileData(parsed.data as Record<string, string>[]);
        }
      };
    }
  };

  const handleGenerateTerms = (): void => {
    if (Array.isArray(inputValue)) {
      dispatch(
        generateTerms({
          includedTerms: [...inputValue],
        })
      );
    }
  };

  const handleAddSuggestedTerm = (term: string): void => {
    dispatch(
      receiveNewKeyword({
        languageMatcherId,
        parentType: type,
        data: {
          key: form,
          value: [term],
        },
      })
    );

    dispatch(
      receiveNewKeyword({
        languageMatcherId,
        parentType: type,
        data: {
          key: 'saved',
          value: false,
        },
      })
    );
  };

  if (inputHidden === true) {
    return null;
  }

  const filteredGeneratedTerms = [...new Set(generatedTerms.map((t) => t.toLowerCase()))].filter(
    (t) =>
      Array.isArray(inputValue) &&
      !inputValue.map((term) => term.toLowerCase()).includes(t.toLowerCase())
  );

  const renderedGeneratedTerms = (): JSX.Element => {
    if (generatedTerms.length > 0 && filteredGeneratedTerms.length === 0) {
      return <span className="text-small">No new suggestions</span>;
    }

    return (
      <>
        {filteredGeneratedTerms.map((term: string) => (
          <button
            type="button"
            key={term}
            className="rounded px-1 py-0.5 bg-litlingo-gray-1 text-small focus:outline-none"
            onClick={(): void => handleAddSuggestedTerm(term)}
          >
            {term}
          </button>
        ))}
      </>
    );
  };

  return (
    <div className="flex flex-col gap-2">
      <div>
        <div className="flex flex-row">
          <span>{inputName}</span>
          <span className="text-litlingo-alert">*</span>
        </div>

        <div className="w-1/2">
          <RenderForm
            inputType={inputType}
            inputValue={inputValue || false}
            inputChoices={inputChoices}
            form={form}
            type={type}
            languageMatcherId={languageMatcherId}
            setError={setError}
          />
        </div>
      </div>

      {error && <span className="text-sm text-red-500">{error}</span>}
      {inputValue && Array.isArray(inputValue) && (
        <div className="flex flex-wrap gap-1">
          {inputValue
            .slice()
            .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
            .map((keyword, idx) => (
              <React.Fragment key={keyword}>
                <LanguageMatcherList
                  hidden={idx > 9 && !showAll}
                  id={languageMatcherId}
                  keyword={keyword}
                  type={form}
                  useDisabledColor={useDisabledColor}
                />
                {idx === 10 && !showAll && (
                  <div
                    role="button"
                    aria-hidden
                    className="ml-2 cursor-pointer"
                    onClick={(): void => {
                      setShowAll(true);
                    }}
                  >
                    <span className="text-sm text-blue-800 hover:underline">Show all</span>
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>
      )}

      {enableGenerate && (
        <div className="flex flex-row gap-2 justify-between pt-1 border-t border-litlingo-gray-1">
          <div className="flex flex-row flex-wrap gap-1">{renderedGeneratedTerms()}</div>

          <div className="flex flex-row gap-2">
            <button
              type="button"
              className="button button--secondary text-body h-6 w-24 justify-center px-4"
              onClick={handleGenerateTerms}
              disabled={!(Array.isArray(inputValue) && inputValue.length > 0)}
            >
              <span className="whitespace-no-wrap font-bold">
                {generateTermsLoading ? <LoadingIndicator size="3" /> : 'Generate'}
              </span>
            </button>

            {enableImport && (
              <span className="rounded-md self-end">
                <input
                  ref={fileInputEl}
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                  style={{ position: 'fixed', top: '-100em' }}
                />
                <button
                  type="button"
                  onClick={handleFileClick}
                  className="button button--secondary text-body h-6 w-20 justify-center px-4"
                  data-testid="import-button"
                >
                  <span className="whitespace-no-wrap font-bold">Import</span>
                </button>
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Terms;
